import RootThemedLayout from "components/RootThemedLayout";
import { setNavigation } from "features/ContextNavigation/store/contextNavigationSlice";
import HeaderSwitch from "features/Header/HeaderSwitch";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";

const HomeViewDesktop = React.lazy(() => import("./HomeViewDesktop"));
const HomeViewMobile = React.lazy(() => import("./HomeViewMobile"));

const HomeView = () => {
  const upLg = useBreakpoint(up("lg"));
  const dispatch  = useDispatch()

  useEffect(() => {

    dispatch(setNavigation({
      ids: [],
      title: '',
      section: 'viewingRoom'
    }))
  }, [dispatch])

  return (
    <RootThemedLayout>
      <HeaderSwitch />
      {upLg ? <HomeViewDesktop /> : <HomeViewMobile />}
    </RootThemedLayout>
  );
};

export default HomeView;
