import * as React from "react";

const SvgVisiteWebsite = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height={512} viewBox="0 0 1920 1920" width={512} xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs></defs>
    <polyline points="784.6 246.9 58.1 246.9 58.1 1840.5 1524.7 1840.5 1524.7 1069.1 1454.7 1069.1 1454.7 1770.5 126.6 1770.5 126.6 317.9 785.1 316.9" />
    <g>
      <polyline
        style={{ fill: "none" }}
        strokeWidth="90"
        strokeMiterlimit="10"
        stroke="currentColor"
        points="1005.9 118 1740.2 118 1740.2 833.6"
      />
      <line strokeWidth="90" strokeMiterlimit="10" stroke="currentColor" x1="1740.2" y1="120.6" x2="700" y2="1160.8" />
    </g>
  </svg>
);

export default SvgVisiteWebsite;
