import useLogOut from "features/Auth/hooks/useLogOut";
import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import { createQueryConfig } from "./react-query.config";

const ReactQueryProvider = ({ children }: PropsWithChildren<{}>) => {
  const logout = useLogOut();

  const config = useMemo(() => {
    return createQueryConfig(logout);
  }, [logout]);
  const client = useQueryClient();

  useEffect(() => {
    client.setDefaultOptions(config);
  }, [client, config]);

  return <>{children}</>;
};

const initailClient = new QueryClient();

const ReactQueryWrapper = ({ children }: PropsWithChildren<{}>) => {
  // const queryClient = useMemo(() => {
  //   return new QueryClient();
  // }, []);

  return (
    <QueryClientProvider client={initailClient}>
      <>
        <ReactQueryProvider>
          {children}

        </ReactQueryProvider>
      </>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default ReactQueryWrapper;
