import * as React from "react";

const SvgArtworkArrowSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 29"
    className="outlined"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      vectorEffect="non-scaling-stroke"
      d="M19 28L2 14.321 19 1"
      stroke="#000"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgArtworkArrowSvg;
