import { styled } from "@linaria/react";
import FinalFormCheckbox from "components/Checkbox/FinalFormCheckbox/FinalFormCheckbox";
import {primaryFont, secondaryFont} from "styles";
import { up } from "styles/utils/breakpoints";
import em from "styles/utils/em";

export const FilterCheckbox = styled(FinalFormCheckbox)`
  margin-right: 10px;
  display: flex
`;

export const FilterCheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  font-size: 13px;
  font-family: ${primaryFont};
  letter-spacing: ${em("1.3px", "13px")};
  text-transform: none;
  ${up("lg")} {
    letter-spacing: 0.722222px;
  }
`;
