import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import clsx from "clsx";
import "components/Animations/Fade";
import { fadeClass } from "components/Animations/Fade";
import useModal from "hooks/useModal";
import React, { ComponentType } from "react";
import { CSSTransition } from "react-transition-group";
import em from "styles/utils/em";
import SearchFiltersMenuMobile from "./SearchFiltersMenuMobile";

const Root = styled.div`
  position: relative;
`;

const Button = styled.button`
  position: relative;
  z-index: 20;

  display: flex;
  align-items: center;

  min-height: 60px;

  font-size: 15px;
  font-weight: 500;
  letter-spacing: ${em("0.33px", "15px")};
  text-transform: uppercase;
`;

const filtersFormCss = css`
  position: absolute;
  left: 0;
  right: 0;

  top: 100%;
  transition: opacity 200ms ease-in-out;
  z-index: 15;

  background-color: #ffffff;
`;

interface FiltersFormsProps {
  className?: string;
  onClose: () => void;
}

type SearchFiltersMobileProps = {
  className?: string;
  FiltersForm?: ComponentType<FiltersFormsProps>;
};

const SearchFiltersMobile = ({
  className,
  FiltersForm = SearchFiltersMenuMobile,
}: SearchFiltersMobileProps) => {
  const { isOpen, handleClose, handleOpen } = useModal();
  return (
    <Root className={className}>
      <Button className="button" onClick={isOpen ? handleClose : handleOpen}>
        Filters {isOpen ? "-" : "+"}
      </Button>
      <CSSTransition
        mountOnEnter
        unmountOnExit
        classNames={fadeClass}
        in={isOpen}
        timeout={200}
      >
        <FiltersForm
          onClose={handleClose}
          className={clsx("filters", filtersFormCss)}
        />
      </CSSTransition>
    </Root>
  );
};

export default SearchFiltersMobile;
