import { styled } from "@linaria/react";
import clsx from "clsx";
import Collapse from "components/Animations/Collapse/Collapse";
import SvgArtworkArrowSvg from "components/icons/ArtworkArrowSvg";
import Svg from "components/Svg";
import React, { PropsWithChildren, ReactChild, useCallback } from "react";

type MobileNavigationListItemProps = PropsWithChildren<{
  text: ReactChild;
  className?: string;
  id?: string | number;
  expandId?: string | number;
  onClick?: (id?: string | number) => void;
  arrow?: boolean;
}>;

export const ListItemText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  line-height: 40px;

  font-size: 25pt;
  letter-spacing: 0.0115em;
  text-transform: uppercase;

  & p {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-decoration: none;
  }

  & > * {
    min-width: 0;
  }

  & a {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-decoration: none;
  }
`;

export const NestedListItemText = styled(ListItemText)`
  font-weight: 300;
  padding-left: 20px;
`;

const ArtworkIcon = styled(Svg)`
  margin-left: auto;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);

  transition: transform 300ms ease-in-out;

  transform: rotate(180deg);
  &.expandable {
    transform: rotate(-90deg);
  }
  &.expanded {
    transform: rotate(90deg);
  }
`;

const MobileNavigationListItem = ({
  text,
  children,
  className,
  id,
  expandId,
  onClick,
  arrow = true,
}: MobileNavigationListItemProps) => {
  const handleClick = useCallback(() => {
    onClick && onClick(id);
  }, [id, onClick]);
  return (
    <li className={className}>
      <ListItemText as={!!children ? "button" : "div"} onClick={handleClick}>
        {text}
        {arrow && (
          <ArtworkIcon
            className={clsx({
              expandable: !!id,
              expanded: id && expandId === id,
            })}
          >
            <SvgArtworkArrowSvg />
          </ArtworkIcon>
        )}
      </ListItemText>
      {children && id && (
        <Collapse in={expandId === id} timeout={300}>
          <NestedListItemText>{children}</NestedListItemText>
        </Collapse>
      )}
    </li>
  );
};

export default MobileNavigationListItem;
