import { styled } from "@linaria/react";
import clsx from "clsx";
import { fadeClass } from "components/Animations/Fade";
import React, {
  PropsWithChildren,
  ReactChild,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { NavSectionType } from "../nested-navigation.interfaces";
import { useNestedNavigation } from "./NestedNavigationProvider";

type NestedNavigationItemProps = PropsWithChildren<{
  section: NavSectionType;
  className?: string;
  label: ReactChild;
}>;

export const NestedNavigationItem = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;

  &.space-lg {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const NestedNavigationListItem = ({
  section,
  className,
  children,
  label,
}: NestedNavigationItemProps) => {
  const {
    selectedSection,
    mouseOverNavigationRef,
    selectSection,
  } = useNestedNavigation();
  const active = section === selectedSection;

  const handleMouseEnter = useCallback(() => {
    selectSection(section);
  }, [section, selectSection]);

  const handleMouseLeave = useCallback(() => {
    if (!mouseOverNavigationRef.current) {
      selectSection(undefined);
    }
    selectSection(section);
  }, [mouseOverNavigationRef, section, selectSection]);

  const [expanded, setExpanded] = useState(!!selectedSection);

  const reactiveExpanded = !!selectedSection;
  const timerRef = useRef<number>();
  useEffect(() => {
    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    }
    timerRef.current = window.setTimeout(
      () => setExpanded(reactiveExpanded),
      100
    );
  }, [reactiveExpanded]);

  return (
    <NestedNavigationItem
      className={clsx(className, {
        active,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
      {children && (
        <CSSTransition
          in={active}
          mountOnEnter
          unmountOnExit
          timeout={100}
          classNames={!expanded || !active ? fadeClass : undefined}
        >
          {children}
        </CSSTransition>
      )}
    </NestedNavigationItem>
  );
};

export default NestedNavigationListItem;
