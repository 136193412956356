import { css } from "@linaria/core";
import { styled } from "@linaria/react";

export const svgCss = css`
  color: #000000;

  .dark & {
    color: #ffffff;
  }

  & > svg {
    display: block;
    height: 1em;
  }

  &.filled > svg path,
  & > svg.filled,
  & > svg path.filled,
  & > svg circle.filled {
    fill: currentColor;
  }
  &.outlined > svg path,
  & > svg.outlined path,
  & > svg path.outlined,
  & > svg circle.outlined {
    stroke: currentColor;
  }
`;

const Svg = styled.div`
  color: #000000;

  .dark & {
    color: #ffffff;
  }

  & > svg {
    display: block;
    height: 1em;
  }

  &.filled > svg path,
  & > svg.filled,
  & > svg path.filled,
  & > svg circle.filled {
    fill: currentColor;
  }
  &.outlined > svg path,
  & > svg.outlined path,
  & > svg path.outlined,
  & > svg circle.outlined {
    stroke: currentColor;
  }
`;

export default Svg;
