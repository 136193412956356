import { styled } from "@linaria/react";
import React, { useCallback } from "react";
import { down } from "styles/utils/breakpoints";
// import { up } from "styles/utils/breakpoints";
import { SnackbarComponentProps } from "./snackbar.interfaces";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 50px;
  padding: 14px;

  font-size: 14px;

	${down('lg')} {
		padding: 0;
	}
  
  text-transform: uppercase;
  transition: opacity 300ms ease-in-out;
`;

const SnackItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;


	padding: 40px;
	background-color: #ffffff;	

	${down('lg')} {
		width: 100%;
		border: 1px solid black;
	}
`

const SnackbarCustom = ({ message, onClose, className }: SnackbarComponentProps) => {
  const handleClose = useCallback(() => {
    onClose(message.key);
  }, [message.key, onClose]);

  return (
    <Root className={className}>
			<SnackItem onClick={handleClose}>
      	{message.text}
			</SnackItem>
    </Root>
  );
};

export default SnackbarCustom;
