import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
  active: boolean;
  isCollapsed: boolean,
  isFullScreen: boolean
}

const initialState: StateType = {
  active: false,
  isCollapsed: true,
  isFullScreen: false
};

const { reducer, actions } = createSlice({
  name: "vrModal",
  initialState,
  reducers: {
    setActive(state, action: PayloadAction<StateType['active']>) {
      state.active = action.payload;
    },
    setIsCollapsed(state, action) {
      state.isCollapsed = action.payload
    },
    setIsFullScreen(state, action) {
      state.isFullScreen = action.payload
    }
  }
});

export const { setActive, setIsCollapsed, setIsFullScreen } = actions;

export default reducer;
