import * as React from "react";

const SvgSave = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" height={19} className="filled" viewBox="0 0 1920 1920" {...props}>
    <path
      strokeWidth="90"
      strokeMiterlimit="10"
      stroke="currentColor"
      d="M1647.9,1846.7L960,1102.1l-687.9,744.5V73.3h1375.9V1846.7z M960,999l617.9,668.8V143.3H342.1v1524.4L960,999z"
    />
  </svg>
);

export default SvgSave;
