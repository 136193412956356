import { styled } from "@linaria/react";
import clsx from "clsx";
import Footer from "components/Footer/Footer";
import RootThemedLayout from "components/RootThemedLayout";
import HeaderSwitch from "features/Header/HeaderSwitch";
import useConstants from "features/ServerConstants/hooks/useConstants";
import React, { useEffect, useMemo } from "react";
import { down } from "styles/utils/breakpoints";
import FaqContextNavigation from "../FaqContext/FaqContextNavigation";

interface FAQProps {
  Header?: React.ComponentType<any>;
}

const Title = styled.div`
  position: sticky;
  font-family: 'Univers';
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  text-align: center;

  ${down('md') }{
    height: 58px;
  }

  &.NotFirst {
    border-top: 1px solid #000;
  }
`;


const Body = styled.div`
  font-family: Univers;
  max-width: 530px;
  margin: 0 auto;
  font-size: 15px;
  padding: 0 20px 20px;
  margin-top: 56px;
  line-height: 19px;
  letter-spacing: 0.25px;

  color: #000;
  ${down('md') }{
    margin-top: 20px;
  } 
  .group {
    margin-bottom: 20px;
  }

  ul {

    > .group > li {
      list-style: none;
      text-decoration: underline;
    }
  }

  section {
    margin-bottom: 25px;
    .bold {
      font-weight: bold;
    }
    a {
      color: blue;
      text-decoration: underline;
    }
  }
  section:last-of-type {
    margin: 0;
  }
`;

const FAQ = ({ Header = HeaderSwitch }: FAQProps) => {
  const { data: constants } = useConstants()

  const faqContent = useMemo(() => {
    return constants?.FaqContent || []
  }, [constants])

  return (
    <RootThemedLayout>
      <Header />
      <FaqContextNavigation />

      {faqContent.map((item, index) => (
        <>
          <Title className={clsx({NotFirst: index !== 0})}>{ item.title }</Title>
          <Body dangerouslySetInnerHTML={{ __html: item.content }} />
        </>
      ))}
      <Footer />
    </RootThemedLayout>
  );
};

export default FAQ;
