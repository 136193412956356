import RootLayout from "components/Layout/RootLayout/RootLayout";
import { artworkRouteConfig } from "features/Artwork/artwork.routes";
import PublicArtworkView from "features/Artwork/ArtworkDetailMobile/PublicArtworkView";
import ClosedView from "features/Closed/ClosedView";
import useCloseLogin from "features/Closed/hooks/useCloseLogin";
// import EnterBanner from "features/Login/EnterBanner";
import {bypassLoginRoute, loginRoute} from "features/Login/login.routes";
import NextRedirect from "features/Next/NextRedirect";
import PostSignUp from "features/PostSignUp/PostSignUp";
import React, { Suspense } from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import BypassAuth from "../BypassAuth";

const PublicContent = () => {
  const closed = useCloseLogin();

  const {pathname} = useLocation()

  return (
    <Suspense fallback={null}>
      <RootLayout>
        {!closed ? (
          <Switch>
            <Route
              path={artworkRouteConfig.path}
              component={PublicArtworkView}
            />
            <Route path="/in-app" exact component={PostSignUp} />
            <Route path={loginRoute.path} exact component={BypassAuth} />

            <Route path={bypassLoginRoute.path} exact component={BypassAuth}/>
            {/* <Route path={publicRoute.path} exact component={EnterBanner} /> */}
            {pathname !== bypassLoginRoute.path  && <NextRedirect to={loginRoute.link()} />}

            {/* <Redirect path="/" to={{ ...location, pathname: publicRoute.link }} /> */}
          </Switch>
        ) : (
          <Switch>
            <Route path={bypassLoginRoute.path} exact component={BypassAuth}/>
            <Route path="/developer-login" exact component={BypassAuth} />

            <Route path="/" exact component={ClosedView} />
            <Redirect to="/" />
            {/* <Redirect path="/" to={{ ...location, pathname: publicRoute.link }} /> */}
          </Switch>
        )}

      </RootLayout>
    </Suspense>
  );
};

export default PublicContent;
