import clsx from "clsx";
import React from "react";
import classes from "./Typography.module.scss";

type TypographyProps = React.PropsWithChildren<
  {
    component?:
      | string
      | React.FunctionComponent<React.HTMLAttributes<HTMLElement>>;
    variant?:
      | "darkScreen"
      | "title"
      | "title1"
      | "caption"
      | "body1"
      | "body2"
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
      | "p";
    center?: boolean;
    uppercase?: boolean;
    dark?: boolean;
  } & React.HTMLAttributes<HTMLElement>
>;

const Typography = ({
  component: Component = "p",
  className,
  center,
  dark,
  uppercase = false,
  variant = "body1",
  ...rest
}: TypographyProps) => {
  return (
    <Component
      className={clsx(className, classes.root, {
        [classes.darkScreenVariant]: variant === "darkScreen",
        [classes.titleVariant]: variant === "title",
        [classes.title1Variant]: variant === "title1",
        [classes.captionVariant]: variant === "caption",
        [classes.body1Variant]: variant === "body1",
        [classes.body2Variant]: variant === "body2",
        [classes.center]: center,
        [classes.h1]: variant === "h1",
        [classes.h2]: variant === "h2",
        [classes.h3]: variant === "h3",
        [classes.h4]: variant === "h4",
        [classes.h5]: variant === "h5",
        [classes.h6]: variant === "h6",
        [classes.p]: variant === "p",
        [classes.dark]: dark,
        [classes.uppercase]: uppercase,
      })}
      {...rest}
    />
  );
};

export default Typography;
