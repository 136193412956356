import { useCallback, useState } from "react";

type InitialType = (() => boolean) | boolean;

export default function useModal(initialState: InitialType = false) {
  const [isOpen, setOpen] = useState(initialState);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return { isOpen, handleClose, handleOpen };
}
