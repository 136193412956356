import * as React from "react";

const SvgMobileFriezeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={126}
    height={126}
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 111c0 8.284 6.716 15 15 15h96c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15H15C6.716 0 0 6.716 0 15v96z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.202 73.132h4.419v-2.091H117V78h-9.798v-4.868zm0-6.198h6.532v-4.868h-6.532v4.868zm4.419-10.8h-4.419V51H117v7.225h-5.379v-2.091zM98.558 73.132h1.921V56.134h-1.921V51h7.569v27h-7.57v-4.868zm-7.723-16.048h6.148L86.186 72.181h-6.148l10.797-15.097zM79.539 73.132h12.756v-2.091h5.187V78H79.539v-4.868zm0-22.132h17.943v5.134H84.957v2.091h-5.418V51zM68.666 73.132h4.649v-2.091h5.148V78h-9.797v-4.868zm0-6.198h6.531v-4.868h-6.531v4.868zm4.649-10.8h-4.65V51h9.798v7.225h-5.148v-2.091zM60.02 73.132h1.96V56.134h-1.96V51h7.57v27h-7.57v-4.868zm-10.643 0h1.96V56.134h-1.96V51h9.567v5.134h-1.96v16.998h1.96V78h-9.567v-4.868zM36.7 68.112h-1.421V62.98h4.803l5.878 10.153h2.344V78H42.54l-5.84-9.887zm4.304-5.513c1.152-.723 1.729-1.826 1.729-3.043 0-1.94-1.422-3.422-3.458-3.422h-3.996V51h4.649c4.726 0 8.222 3.689 8.222 8.556 0 3.309-1.614 6.009-4.534 7.568l-2.612-4.525zM26.595 73.132h1.998V56.134h-1.998V51h7.607v22.132h1.96V78h-9.567v-4.868zm-10.873-6.198h6.493v-4.868h-6.493v4.868zm4.38-10.8h-4.38V51h9.797v7.225h-5.418v-2.091zM7 73.132h1.998V56.134H7V51h7.646v22.132h1.96V78H7v-4.868z"
      fill="#fff"
    />
  </svg>
);

export default SvgMobileFriezeIcon;
