import clsx from "clsx";
import Collapse from "components/Animations/Collapse/Collapse";
import React, { PropsWithChildren, useCallback } from "react";
import { NestedItemKey, useNestedMenu } from "./NestedMenu";

type NestedMenuItemProps = PropsWithChildren<{
  nestedItemKey: NestedItemKey;
  className?: string;
  activeClassName?: string;
  title: React.ReactChild;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}>;

const NestedMenuItem = ({
  children,
  nestedItemKey,
  className,
  activeClassName = "active",
  title,
  onClick,
}: NestedMenuItemProps) => {
  const { expand, expanded } = useNestedMenu();

  const active = !!expanded[nestedItemKey];
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      expand(nestedItemKey);
      onClick && onClick(e);
    },
    [expand, nestedItemKey, onClick]
  );
  return (
    <>
      <button
        onClick={handleClick}
        className={clsx(className, {
          [activeClassName]: active,
        })}
        type="button"
      >
        {title}
      </button>
      {children && (
        <Collapse in={active} timeout={300}>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default NestedMenuItem;
