export const publicRoute = {
  path: "/",
  link: "/",
};

export const loginRoute = {
  path: "/login",
  link: () => "/login",
};

export const bypassLoginRoute = {
  path: "/vistajet",
  link: () => "/login"
}

export const devSignInRoute = {
  path: "/developer-sign-in",
  link: "/developer-sign-in",
};

export const previewRoutes = {
  path: "/preview",
  link: "/preview",
};
