import * as React from "react";

const SvgBurgerMenuMobile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="outlined"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.303 19.5H21.75M1.303 1.5H21.75M1.303 10H21.75"
      stroke="#000"
      strokeWidth={1.1}
      strokeLinecap="square"
    />
  </svg>
);

export default SvgBurgerMenuMobile;
