import useConstants from "features/ServerConstants/hooks/useConstants";
import { useMemo } from "react";
import { SearchFilters } from "../interfaces/search.interfaces";

export type FilterePanelKeys = keyof Omit<SearchFilters, "name">;

type FiltersArray = {
  filter: FilterePanelKeys;
  label: string;
  items?: { key: string; label: string; type: "checkbox" | "radio" }[];
};

type FiltersMap = Partial<
  Record<
    FilterePanelKeys,
    { key: string; label: string; type: "checkbox" | "radio" }[] | undefined
  >
>;
export default function useFiltersMenu() {
  const { data } = useConstants();
  const filtersMenuItemsArray: FiltersArray[] = useMemo(() => [
      {
        filter: "sections",
        label: "Section",
        items: data?.Section.filter((i) => i.visible).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "regions",
        label: "Region",
        items: data?.Region.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "mediums",
        label: "Medium",
        items: data?.Medium.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "categories",
        label: "Category",
        items: data?.ArtworkCategory.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "priceRanges",
        label: "Price",
        items: data?.PriceRange.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "centuryRanges",
        label: "Date",
        items: data?.CenturyRange.filter((i) => i.isActive).map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "years",
        label: "Year",
        items: data?.Year.map((value) => ({
          key: value.id.toString(),
          label: value.name,
          type: "checkbox",
        })),
      },
      {
        filter: "sortBy",
        label: "Sort By",
        items: [
          { key: "asc", label: "A-Z", type: "radio" },
          { key: "desc", label: "Z-A", type: "radio" },
        ],
      },
    ],
    [data]
  );
  const filtersMenuItems = useMemo(() => {
    return filtersMenuItemsArray.reduce((acc, item) => {
      acc[item.filter] = item.items;
      return acc;
    }, {} as FiltersMap);
  }, [filtersMenuItemsArray]);
  return { filtersMenuItems, filtersMenuItemsArray };
}
