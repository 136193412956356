import SubheaderBase from "features/Header/SubheaderBase/SubheaderBase";
import React, { PropsWithChildren, ReactChild } from "react";
import classes from "./MobileBanner.module.scss";

/**
 * @deprecated since version 3.0
 */
const MobileBanner = ({
  children,
  hideHeader,
  hideDates,
  footer,
}: PropsWithChildren<{
  hideHeader?: boolean;
  hideDates?: boolean;
  footer?: ReactChild;
}>) => {
  return (
    <div className={classes.root}>
      <SubheaderBase>
        Explore art from the world’s leading galleries
      </SubheaderBase>
      <div className={classes.content}>
        {!hideDates && (
          <>
            <h1 className={classes.title}>
              Independent New York <br /> 2021 Edition{" "}
            </h1>
            <p className={classes.dates}>
              Preview 7-8 October <br /> 9-16 October{" "}
            </p>
          </>
        )}
        {children}
      </div>
      {footer ? (
        footer
      ) : (
        <div className={classes.partners}>
          <div className={classes.ftWrapper}>
            <div>Global media partner</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileBanner;
