import { getAppBannerPath } from "config";
import AuthView from "features/Auth/AuthView";
import DownloadAppBanner from "features/ForNative/DownloadAppBanner/DownloadAppBanner";
import { previewRoutes } from "features/Login/login.routes";
import { publicNewsRoute } from "features/News/news.routes";
import PublicNews from "features/News/PublicNews/PublicNews";
import ViewingRoomPreview from "features/ViewingRoom/components/ViewingRoomPreview/ViewingRoomPreview";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PublicContent from "./PublicContent";
import SecureContent from "./SecureContent";

const RootRouter = () => {
  return (
    <Switch>
      <Route path={previewRoutes.path} component={ViewingRoomPreview} />
      <Route path={publicNewsRoute} exact component={PublicNews} />
      <Route path={getAppBannerPath} exact component={DownloadAppBanner} />
      <Route
        render={() => (
          <AuthView
            secureView={<SecureContent />}
            publicView={<PublicContent />}
          />
        )}
      />
    </Switch>
  );
};

export default RootRouter;
