import clsx from "clsx";
import NavigationLink from "features/Navigation/components/NavigationLink";
import React, { ComponentProps, ComponentType, useCallback } from "react";
import { LinkProps } from "react-router-dom";

export type BaseButtonProps = {
  to?: LinkProps["to"];
  link?: boolean;
  replace?: boolean;
  classes?: Partial<{
    root: string;
    content: string;
  }>;
  linkProps?: Partial<ComponentProps<typeof NavigationLink>>;
  nativeLinkProps?: Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>>;
  nativeLink?: boolean;
  goBack?: boolean;
  addGoBackState?: boolean;
  modal?: string;
  darkTheme?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton = ({
  children,
  classes: externalClasses = {},
  link,
  className,
  linkProps,
  disabled,
  onClick,
  nativeLink,
  to,
  nativeLinkProps,
  ...props
}: BaseButtonProps) => {
  const Component: ComponentType<any> | string =
    link || nativeLink ? (nativeLink ? "a" : NavigationLink) : "button";

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      if (onClick) {
        onClick(e as any);
      }
    },
    [disabled, onClick]
  );

  return (
    <Component
      className={clsx(externalClasses.root, className)}
      {...props}
      {...linkProps}
      {...nativeLinkProps}
      href={nativeLink ? to : undefined}
      to={link ? to : undefined}
      onClick={handleClick}
      disabled={disabled}
    >
      <div className={externalClasses.content}>{children}</div>
    </Component>
  );
};

export default BaseButton;
