import * as React from "react";

const SvgIndependentHeaderLogoMobile = (props: React.SVGProps<SVGSVGElement>) => (
        <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1350.000000 240"
                {...props}
        >
          <g transform="translate(0.000000,340) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M3710 3225 c-62 -33 -153 -61 -229 -70 -71 -8 -101 -29 -101 -71 0 -43 19 -64 80 -91 74 -31 80 -48 80 -199 l0 -113 -57 27 c-44 20 -78 27 -140 30 -184 9 -314 -71 -399 -243 -45 -91 -64 -185 -64 -315 0 -195 47 -328 145 -418 68 -62 132 -86 235 -85 96 0 157 29 229 110 l51 57 0 -71 c0 -50 4 -74 15 -83 20 -17 86 -8 173 24 40 14 107 31 150 37 91 13 112 28 112 80 0 42 -5 48 -70 69 -38 13 -45 20 -52 50 -5 19 -7 314 -6 655 2 341 -1 626 -5 633 -14 21 -97 14 -147 -13z m-247 -639 c80 -29 77 -15 77 -328 l0 -279 -43 -43 c-50 -49 -98 -64 -152 -46 -76 25 -108 124 -108 340 -1 219 36 326 124 357 44 15 56 15 102 -1z"/>
            <path d="M9450 3225 c-62 -33 -153 -61 -229 -70 -71 -8 -101 -29 -101 -71 0 -43 19 -64 80 -91 74 -31 80 -48 80 -199 l0 -113 -57 27 c-44 20 -78 27 -140 30 -184 9 -314 -71 -399 -243 -45 -91 -64 -185 -64 -315 0 -195 47 -328 145 -418 68 -62 132 -86 235 -85 96 0 157 29 229 110 l51 57 0 -71 c0 -50 4 -74 15 -83 20 -17 86 -8 173 24 40 14 107 31 150 37 91 13 112 28 112 80 0 42 -5 48 -70 69 -38 13 -45 20 -52 50 -5 19 -7 314 -6 655 2 341 -1 626 -5 633 -14 21 -97 14 -147 -13z m-247 -639 c80 -29 77 -15 77 -328 l0 -279 -43 -43 c-50 -49 -98 -64 -152 -46 -76 25 -108 124 -108 340 -1 219 36 326 124 357 44 15 56 15 102 -1z"/>
            <path d="M1320 3179 c-69 -4 -228 -4 -353 -1 -191 4 -230 3 -238 -9 -13 -21 -11 -93 3 -107 7 -7 41 -12 83 -12 42 0 76 -5 83 -12 19 -19 17 -1167 -2 -1193 -11 -15 -31 -20 -97 -25 l-84 -5 -3 -48 c-2 -27 1 -54 7 -60 15 -19 790 -15 802 4 11 18 11 52 0 82 -9 23 -15 25 -90 28 -48 3 -85 9 -93 17 -10 10 -13 133 -13 603 0 513 2 591 15 599 8 5 44 10 80 10 84 0 95 8 95 65 0 49 -21 77 -54 73 -9 -1 -72 -5 -141 -9z"/>
            <path d="M12355 2898 c-14 -29 -25 -58 -25 -64 0 -5 -13 -31 -29 -57 -36 -56 -113 -103 -185 -112 -77 -11 -86 -18 -86 -73 0 -27 5 -53 12 -60 7 -7 39 -12 75 -12 l63 0 0 -302 c0 -322 6 -372 50 -443 31 -50 92 -83 178 -95 188 -26 380 56 399 173 11 68 -29 99 -92 72 -47 -19 -108 -19 -144 -1 -52 27 -56 48 -59 334 l-3 262 141 0 c162 0 154 -5 155 98 0 45 -4 65 -15 72 -8 5 -75 10 -149 10 l-134 0 6 110 c8 132 3 140 -79 140 l-55 0 -24 -52z"/>
            <path d="M4444 2736 c-184 -35 -323 -174 -369 -368 -20 -84 -19 -267 0 -343 55 -210 212 -337 435 -352 146 -9 279 31 369 113 79 71 118 174 82 218 -22 27 -76 20 -115 -14 -99 -87 -257 -100 -356 -29 -51 37 -96 127 -106 210 l-6 56 296 -1 c253 -1 298 1 306 14 5 8 10 50 10 92 0 285 -239 462 -546 404z m166 -144 c16 -16 33 -46 39 -68 11 -43 26 -173 20 -175 -2 -1 -69 -4 -148 -8 l-144 -6 6 65 c13 142 67 220 153 220 37 0 51 -6 74 -28z"/>
            <path d="M6714 2736 c-184 -35 -323 -174 -369 -368 -20 -84 -19 -267 0 -343 55 -210 212 -337 435 -352 146 -9 279 31 369 113 79 71 118 174 82 218 -22 27 -76 20 -115 -14 -99 -87 -257 -100 -356 -29 -51 37 -96 127 -106 210 l-6 56 296 -1 c253 -1 298 1 306 14 5 8 10 50 10 92 0 285 -239 462 -546 404z m166 -144 c16 -16 33 -46 39 -68 11 -43 26 -173 20 -175 -2 -1 -69 -4 -148 -8 l-144 -6 6 65 c13 142 67 220 153 220 37 0 51 -6 74 -28z"/>
            <path d="M10194 2736 c-184 -35 -323 -174 -369 -368 -20 -84 -19 -267 0 -343 55 -210 212 -337 435 -352 146 -9 279 31 369 113 79 71 118 174 82 218 -22 27 -76 20 -115 -14 -99 -87 -257 -100 -356 -29 -51 37 -96 127 -106 210 l-6 56 296 -1 c253 -1 298 1 306 14 5 8 10 50 10 92 0 285 -239 462 -546 404z m166 -144 c16 -16 33 -46 39 -68 11 -43 26 -173 20 -175 -2 -1 -69 -4 -148 -8 l-144 -6 6 65 c13 142 67 220 153 220 37 0 51 -6 74 -28z"/>
            <path d="M1881 2704 c-52 -19 -128 -40 -170 -46 -42 -6 -82 -16 -88 -21 -18 -13 -16 -92 1 -106 8 -7 31 -15 53 -18 21 -3 44 -12 51 -21 9 -12 12 -94 12 -333 0 -175 -3 -324 -6 -333 -4 -11 -19 -16 -49 -16 -51 0 -75 -19 -75 -58 0 -55 -3 -54 281 -56 284 -1 289 0 289 52 0 41 -14 55 -62 61 l-43 6 -3 328 -2 328 47 21 c63 28 145 28 181 1 46 -34 51 -66 51 -370 1 -155 -2 -288 -5 -297 -4 -11 -19 -16 -45 -16 -28 0 -41 -5 -49 -19 -13 -26 -13 -78 1 -87 15 -9 528 -11 542 -2 17 10 14 87 -3 98 -8 5 -31 10 -51 10 -19 0 -40 5 -47 12 -9 9 -12 93 -12 318 0 178 -5 331 -11 365 -30 165 -129 243 -293 233 -81 -5 -153 -36 -225 -96 -24 -21 -53 -45 -65 -54 l-22 -17 4 64 c4 54 1 66 -17 84 -29 29 -59 26 -170 -15z"/>
            <path d="M5350 2708 c-41 -17 -109 -35 -150 -40 -101 -13 -116 -20 -123 -57 -9 -45 7 -79 40 -86 52 -11 73 -25 83 -51 13 -34 13 -1144 0 -1178 -8 -23 -15 -26 -58 -26 -26 0 -53 -5 -60 -12 -16 -16 -15 -83 1 -96 16 -13 578 -13 595 0 6 6 12 30 12 54 0 49 -4 52 -95 56 -47 3 -50 5 -56 33 -6 28 -12 405 -6 405 1 0 24 -7 51 -15 27 -8 84 -15 125 -15 235 0 395 126 458 360 22 81 24 299 4 380 -33 135 -111 243 -208 292 -42 20 -63 23 -159 23 -105 0 -113 -1 -155 -29 -24 -16 -60 -47 -80 -68 l-36 -39 5 53 c3 42 0 58 -13 71 -25 26 -90 20 -175 -15z m411 -184 c52 -50 64 -113 64 -334 0 -261 -24 -342 -111 -370 -50 -17 -99 -4 -145 37 l-39 35 0 299 0 298 33 25 c48 37 77 46 125 42 31 -3 52 -12 73 -32z"/>
            <path d="M7621 2704 c-52 -19 -128 -40 -170 -46 -42 -6 -82 -16 -88 -21 -18 -13 -16 -92 1 -106 8 -7 31 -15 53 -18 21 -3 44 -12 51 -21 9 -12 12 -94 12 -333 0 -175 -3 -324 -6 -333 -4 -11 -19 -16 -49 -16 -51 0 -75 -19 -75 -58 0 -55 -3 -54 281 -56 284 -1 289 0 289 52 0 41 -14 55 -62 61 l-43 6 -3 328 -2 328 47 21 c63 28 145 28 181 1 46 -34 51 -66 51 -370 1 -155 -2 -288 -5 -297 -4 -11 -19 -16 -45 -16 -28 0 -41 -5 -49 -19 -13 -26 -13 -78 1 -87 15 -9 528 -11 542 -2 17 10 14 87 -3 98 -8 5 -31 10 -51 10 -19 0 -40 5 -47 12 -9 9 -12 93 -12 318 0 178 -5 331 -11 365 -30 165 -129 243 -293 233 -81 -5 -153 -36 -225 -96 -24 -21 -53 -45 -65 -54 l-22 -17 4 64 c4 54 1 66 -17 84 -29 29 -59 26 -170 -15z"/>
            <path d="M11101 2704 c-52 -19 -128 -40 -170 -46 -42 -6 -82 -16 -88 -21 -18 -13 -16 -92 1 -106 8 -7 31 -15 53 -18 21 -3 44 -12 51 -21 9 -12 12 -94 12 -333 0 -175 -3 -324 -6 -333 -4 -11 -19 -16 -49 -16 -51 0 -75 -19 -75 -58 0 -55 -3 -54 281 -56 284 -1 289 0 289 52 0 41 -14 55 -62 61 l-43 6 -3 328 -2 328 47 21 c63 28 145 28 181 1 46 -34 51 -66 51 -370 1 -155 -2 -288 -5 -297 -4 -11 -19 -16 -45 -16 -28 0 -41 -5 -49 -19 -13 -26 -13 -78 1 -87 15 -9 528 -11 542 -2 17 10 14 87 -3 98 -8 5 -31 10 -51 10 -19 0 -40 5 -47 12 -9 9 -12 93 -12 318 0 178 -5 331 -11 365 -30 165 -129 243 -293 233 -81 -5 -153 -36 -225 -96 -24 -21 -53 -45 -65 -54 l-22 -17 4 64 c4 54 1 66 -17 84 -29 29 -59 26 -170 -15z"/>
          </g>
        </svg>
);

export default SvgIndependentHeaderLogoMobile;
