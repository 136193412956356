import React from "react";
import { up } from "styles/utils/breakpoints";
import useBreakpoint from "styles/utils/useBreakpoint";

const LoginBanner = React.lazy(() => import("./login/LoginDesktop"));
const LoginMobile = React.lazy(() => import("./login/LoginMobile"));

const BypassAuth = () => {
  const upLg = useBreakpoint(up("lg"));

  return upLg ? <LoginBanner /> : <LoginMobile />;
};

export default BypassAuth;
