import usePreviewSession from "features/Auth/hooks/usePreviewSession";
import useConstants from "features/ServerConstants/hooks/useConstants";
import React from "react";
import { Route, Switch } from "react-router-dom";
import PreviewContent from "./PreviewContent";
import UserContent from "./UserContent";

export default function SecureContent() {
  useConstants();

  const { isPreview } = usePreviewSession();

  return (
    <Switch>
      <Route
        render={() => (isPreview ? <PreviewContent /> : <UserContent />)}
      />
    </Switch>
  );
}
