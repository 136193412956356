import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logIn } from "features/Auth/store/authSlice";
import ky from "ky";
import { AppThunk } from "store";

const signInEndpoint = "/api/v1/public/auth";

export type SignInFormType = {
  phone: string;
};

type SignInStep = "form" | "code";

const initialState: {
  formData?: SignInFormType;
  step: SignInStep;
} = {
  step: "form",
};

const { reducer, actions } = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    setFormData(state, action: PayloadAction<{ formData: SignInFormType }>) {
      state.formData = action.payload.formData;
    },
    setCodeStep(state) {
      state.step = "code";
    },
    clearSignIn() {
      return initialState;
    },
  },
});

export default reducer;

export const { setFormData, clearSignIn, setCodeStep } = actions;

export const signIn = (code: string): AppThunk => async (
  dispatch,
  getState
) => {
  const { signIn } = getState();
  if (!signIn.formData) {
    return;
  }
  const { phone } = signIn.formData;
  const signUpData = {
    phone,
    code,
  };

  const request = await ky.post(signInEndpoint, {
    method: "POST",
    json: { email: signUpData.phone, password: signUpData.code },
  });

  const { token } = await request.json();
  dispatch(logIn(token));
  dispatch(clearSignIn());
};
