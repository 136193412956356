import { wsAnalyticsHost } from "config";
import CustomEvent from "features/Websocket/CustomEvent";
import WsClient from "features/Websocket/WsClient";
import tokenStorage from "utils/tokenStorage";

type AnalyticsMessage = {
  id: string;
};

export class AnalyticsClient extends WsClient {
  newMessageEvent: CustomEvent<void> = new CustomEvent<void>();
  connectToServer() {
    super.connect(
      `wss://${wsAnalyticsHost}/stats/analytics?token=${
        tokenStorage.getToken() || ""
      }`
    );
  }
  enteredArt(id: string) {
    this.sendMessage<AnalyticsMessage>("entered-art", {
      id,
    });
  }
  leftArt(id: string) {
    this.sendMessage<AnalyticsMessage>("left-art", {
      id,
    });
  }
  enteredShow(id: string) {
    this.sendMessage<AnalyticsMessage>("entered-show", {
      id,
    });
  }
  leftShow(id: string) {
    this.sendMessage<AnalyticsMessage>("left-show", {
      id,
    });
  }
  visitWebsiteArt(id:string) {
    this.sendMessage<AnalyticsMessage>("website-art", {
      id,
    });
    this.sendMessage<AnalyticsMessage>("website-show", {
      id,
    });
  }
  sharedArt(id: string) {
    this.sendMessage<AnalyticsMessage>("shared-art", {
      id,
    });
  }
}
