import { createApi } from "features/api";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { isMobile } from "utils/platform";
import previewTokenStorage from "utils/previewTokenStorage";
import tokenStorage from "utils/tokenStorage";
import App from "./features/App/App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

export const api = createApi({
  getToken: () => tokenStorage.getToken(),
  getPreviewToken: () => previewTokenStorage.getToken(),
});

Modal.setAppElement("#root");

if (isMobile) {
  document.documentElement.classList.add("isMobile");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
