import { css } from "@linaria/core";
import { up } from "./utils/breakpoints";
import fluid from "./utils/fluid";

type Variables = {
  lMinWidth: string;
  mMaxWidth: string;
  mMinWidth: string;
  sMaxWidth: string;
  sMinWidth: string;
  sMinHeight: string;
  xsMaxWidth: string;
  xsMaxHeight: string;
  totalHeaderHeight: string;
  totalHeaderHeightNum: number;
};

export const primaryFont = '"Univers"';
export const secondaryFont = '"DTLFleischmannT"';

export const variables: Variables = {
  lMinWidth: "1440px",
  mMaxWidth: "1439px",
  mMinWidth: "1025px",
  sMaxWidth: "1024px",
  sMinWidth: "768px",
  sMinHeight: "501px",
  xsMaxWidth: "767px",
  xsMaxHeight: "500px",
  totalHeaderHeight: "126px",
  totalHeaderHeightNum: 126,
};

export const VISUALLY_HIDDEN = "visually-hidden";

export const DARK_CLASS = "dark";
//Помечаем иконки, чтобы красить их в темной теме
export const ICON_PATH_CLASS = "icon path";
export const ICON_STROKE_CLASS = "icon stroke";

export const colors = {
  dark: "#464646",
  error: "#ff0033",
};

export const zIndex = {
  header: 100,
  popover: 50,
  modal: 1000,
};

export const customProps = {
  headerHeight: "--header-height",
  subHeaderHeight: "--subheader-height",
  layoutPl: "--layout-pl",
  layoutPr: "--layout-pr",
  buttonsHeight: "--filters-buttons-height"
};


export const globals = css`
  :global() {
    @font-face {
      font-family: "Univers";
      src: url(${require("fonts/universal/Univers.otf")}),
        url(${require("fonts/universal/Univers.otf")});
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: "Univers";
      src: url(${require("fonts/universal/UniversLTStd-Bold.otf")}),
        url(${require("fonts/universal/UniversLTStd-Bold.otf")});
      font-weight: bold;
      font-style: normal;
    }
    @font-face {
      font-family: "Univers";
      src: url(${require("fonts/universal/Univers.otf")}),
        url(${require("fonts/universal/Univers.otf")});
      font-weight: 300;
      font-style: normal;
    }
    @font-face {
      font-family: "Univers";
      src: url(${require("fonts/universal/UniversLTStd-Bold.otf")}),
        url(${require("fonts/universal/UniversLTStd-Bold.otf")});
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: "DTLFleischmannT";
      src: url(${require("fonts/DTL/GT-Super-Display-Light-Italic.otf")}),
        url(${require("fonts/DTL/GT-Super-Display-Light-Italic.otf")});
      font-weight: 300;
      font-style: normal;
    }
    @font-face {
      font-family: "DTLFleischmannT";
      src: url(${require("fonts/DTL/DTLFleischmannT-Bold.otf")}),
        url(${require("fonts/DTL/DTLFleischmannT-Bold.otf")});
      font-weight: bold;
      font-style: normal;
    }

    @font-face {
      font-family: "DTLFleischmannT";
      src: url(${require("fonts/DTL/DTLFleischmannT.otf")}),
      url(${require("fonts/DTL/DTLFleischmannT.otf")});
      font-weight: normal;
      font-style: normal;
    }

    html {
      --header-height: 50px;
      --subheader-height: 50px;
      --filters-buttons-height: 162px;

      ${up("lg")} {
        --header-height: 75px;
        --subheader-height: 50px;
      }

      ${fluid(customProps.layoutPl as any, {
        base: "20px",
        lg: "30px",
        xlg: "30px",
        xl: "40px",
      })}
      ${fluid(customProps.layoutPr as any, {
        base: "20px",
        lg: "30px",
        xlg: "30px",
        xl: "40px",
      })}
    }
  }
`;
