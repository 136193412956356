import { styled } from "@linaria/react";
import useMobileMenu from "features/MobileMenu/hooks/useMobileMenu";
import NestedNavigation from "features/NestedNavigation";
import NavigationItemText, {
  navigationItemTextCss,
} from "features/NestedNavigation/components/NavigationItemText";
import NestedList from "features/NestedNavigation/components/NestedList";
import NestedNav from "features/NestedNavigation/components/NestedNav";
import NestedNavigationListItem, {
  NestedNavigationItem,
} from "features/NestedNavigation/components/NestedNavigationListItem";
import NestedNavList from "features/NestedNavigation/components/NestedNavList";
import useConstants from "features/ServerConstants/hooks/useConstants";
import { WithClassNameProps } from "interfaces";
import React from "react";
import NavigationLink from "./components/NavigationLink";
// import { nftRoutes } from "features/Nft/nft.routes";

const ExternaLink = styled.a``;

const isExternalLink = (url: string) => {
  const tmp = document.createElement("a");
  tmp.href = url;
  return tmp.host !== window.location.host;
};

const HeaderNavigation = ({ className }: WithClassNameProps) => {
  const { data: constants } = useConstants();
  const { close } = useMobileMenu();

  return (
    <NestedNavigation className={className}>
      <NestedNav>
        <NestedNavList>
          {constants?.NavigationItem.map((navItem) => (
            <>
              {navItem.type === "link" && (
                <NestedNavigationListItem
                  className="main"
                  section={navItem.name}
                  label={
                    <NavigationItemText data-text={navItem.name}>
                      {!isExternalLink(navItem.value) && (
                        <NavigationLink
                          className={navigationItemTextCss}
                          data-text={navItem.name}
                          onClick={close}
                          addGoBackState
                          to={navItem.value}
                        >
                          {navItem.name}
                        </NavigationLink>
                      )}

                      {isExternalLink(navItem.value) && (
                        <ExternaLink
                          data-text={navItem.name}
                          href={navItem.value}
                          onClick={close}
                        >
                          {navItem.name}
                        </ExternaLink>
                      )}
                    </NavigationItemText>
                  }
                />
              )}

              {navItem.type !== "link" && (
                <NestedNavigationListItem
                  className="main"
                  section={navItem.name}
                  label={
                    <NavigationItemText data-text={navItem.name}>
                      {navItem.name}
                    </NavigationItemText>
                  }
                >
                  {!!navItem.items.length && (
                    <NestedList as="ul">
                      {navItem.items.map((subItem) => (
                        <NestedNavigationItem key={subItem.id}>
                          {!isExternalLink(subItem.value) && (
                            <NavigationLink
                              className={navigationItemTextCss}
                              data-text={subItem.name}
                              onClick={close}
                              addGoBackState
                              to={subItem.value}
                            >
                              {subItem.name}
                            </NavigationLink>
                          )}

                          {isExternalLink(subItem.value) && (
                            <NavigationItemText data-text={subItem.name}>
                              <ExternaLink
                                data-text={subItem.name}
                                href={subItem.value}
                                onClick={close}
                              >
                                {subItem.name}
                              </ExternaLink>
                            </NavigationItemText>
                          )}
                        </NestedNavigationItem>
                      ))}
                    </NestedList>
                  )}
                </NestedNavigationListItem>
              )}
            </>
          ))}
        </NestedNavList>
      </NestedNav>
    </NestedNavigation>
  );
};

export default HeaderNavigation;
