import clsx from "clsx";
import BaseButton, { BaseButtonProps } from "components/BaseButton/BaseButton";
import Typography from "components/Typography/Typography";
import useMergeClasses from "hooks/useMergeClasses";
import React, { ComponentProps } from "react";
import classes from "./Button.module.scss";

type SizeType = "default" | "small";

export type ButtonProps = Omit<BaseButtonProps, "classes"> & {
  pressed?: boolean;
  size?: SizeType;
  color?: "primary" | "secondary";
  classes?: BaseButtonProps["classes"] &
    Partial<{
      text: string;
      fullWidth: string;
    }>;
  variant?: "outlined" | "contained";
  typographyProps?: Partial<ComponentProps<typeof Typography>>;
  fullWidth?: boolean;
  noHover?: boolean;
  disableDark?: boolean;
};
/**
 * @deprecated since version 3.0
 */
export const ButtonOld = ({
  classes: externalClasses = {},
  children,
  className,
  pressed,
  size,
  color = "secondary",
  variant = "outlined",
  typographyProps,
  fullWidth = false,
  noHover = false,
  disableDark = false,
  ...props
}: ButtonProps) => {
  const resultClasses = useMergeClasses(
    {
      root: clsx(externalClasses.root, className, classes.root, {
        [classes.noHover]: noHover,
        [classes.pressed]: pressed,
        [classes.small]: size === "small",
        [classes.primaryColor]: color === "primary",
        [classes.fullWidth]: fullWidth,
        [classes.disableDark]: disableDark,
      }),
      content: clsx(classes.content, {
        [classes.contained]: variant === "contained",
      }),
      text: classes.text,
    },
    externalClasses
  );

  return (
    <BaseButton aria-pressed={pressed} {...props} classes={resultClasses}>
      <Typography
        variant="p"
        center
        className={resultClasses.text}
        {...typographyProps}
      >
        {children}
      </Typography>
    </BaseButton>
  );
};
