import { styled } from "@linaria/react";
import usePreviewSession from "features/Auth/hooks/usePreviewSession";
import { WithClassNameProps } from "interfaces";
import React from "react";
import MenuAction from "./MenuAction";
import ProfileAction from "./ProfileAction";
import SearchAction from "./SearchActionMobile";

interface HeaderControlsProps extends WithClassNameProps {
  search?: boolean;
  menu?: boolean;
}

const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 24px;
  min-width: 23px;
  & + & {
    margin-left: 20px;
  }
  &.menu  {
    min-width: 25px;
  }
  &.menu > button {
    cursor: pointer;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
`;

const HeaderControls = ({
  search = true,
  menu = true,
  className,
}: HeaderControlsProps) => {
  const { isPreview } = usePreviewSession();
  if (isPreview) {
    return null;
  }

  return (
    <List>
      {menu && (
        <ListItem className="menu">
          <MenuAction className="desktop" />
        </ListItem>
      )}

      <ListItem>
        <ProfileAction className="desktop" />
      </ListItem>
      {search && (
        <ListItem>
          <SearchAction className="desktop" />
        </ListItem>
      )}
    </List>
  );
};

export default HeaderControls;
