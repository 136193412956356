import ReactQueryWrapper from "features/api/ReactQueryWrapper";
import LayoutObserver from "features/LayoutUtils/LayoutObserver";
import ScrollController from "features/ScrollController/ScrollController";
import { SnackbarProvider } from "features/Snackbar/SnackbarProvider";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "../../store";
import RootRouter from "./RootRouter";

const App = () => {
  return (
    <Provider store={store}>
      <ReactQueryWrapper>
        <SnackbarProvider>
          <BrowserRouter>
            <LayoutObserver />
            <ScrollController />
            <RootRouter />
          </BrowserRouter>
        </SnackbarProvider>
      </ReactQueryWrapper>
    </Provider>
  );
};

export default App;
